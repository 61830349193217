import {DialogComponent} from "@syncfusion/ej2-react-popups";
import React, {useContext, useEffect, useState} from "react";
import {env, isMobile as checkMobile} from "../../app.constant"
import TermsAndConditionsComponent from "../privacy-policy/terms-and-conditions.component";
import OtherImportantInformationComponent from "../privacy-policy/other-important-information.component";
import {I18nextContext, Trans} from "gatsby-plugin-react-i18next";
import termOfUsePdfBENL from "../../assets/pdf/Terms_of_Use-BE_NL.pdf";
import termOfUsePdfBEFR from "../../assets/pdf/Terms_of_Use-BE_FR.pdf";
import termOfUsePdfNLNL from "../../assets/pdf/Terms_of_Use-NL_NL.pdf";
import termOfUsePdfFIFI from "../../assets/pdf/Terms_of_Use-FI_FI.pdf";
import termOfUseAmtrustPdfBENL from "../../assets/pdf/Terms_of_Use_Amtrust-BE_NL.pdf";
import termOfUseAmtrustPdfBEFR from "../../assets/pdf/Terms_of_Use_Amtrust-BE_FR.pdf";
import termOfUseAmtrustPdfNLNL from "../../assets/pdf/Terms_of_Use_Amtrust-NL_NL.pdf";
import termOfUseAmtrustPdfFIFI from "../../assets/pdf/Terms_of_Use_Amtrust-FI_FI.pdf";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const PrecontractualTouPopup = ({
  show = false, target = "", close = () => { },
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const { language } = useContext(I18nextContext)
  const [getToU, setToU] = useState(false)
  const [getAmtrust, setAmtrust] = useState(false)

  useEffect(() => {
    setIsMobile(checkMobile())
    switch (env.GATSBY_COUNTRY) {
      case "BE":
        if(language === "be_fr"){
          setToU(termOfUsePdfBEFR)
        } else {
          setToU(termOfUsePdfBENL)
        }
        break;
      case "NL":
        setToU(termOfUsePdfNLNL)
        break;
      case "FI":
        setToU(termOfUsePdfFIFI)
        break;

      default:
        break;
    }

    switch (env.GATSBY_COUNTRY) {
      case "BE":
        if(language === "be_fr"){
          setAmtrust(termOfUseAmtrustPdfBEFR)
        } else {
          setAmtrust(termOfUseAmtrustPdfBENL)
        }
        break;
      case "NL":
        setAmtrust(termOfUseAmtrustPdfNLNL)
        break;
      case "FI":
        setAmtrust(termOfUseAmtrustPdfFIFI)
        break;

      default:
        break;
    }
  }, []);

  return (
    <DialogComponent
      visible={show}
      showCloseIcon
      isModal
      width={`${isMobile ? 95 : 80}%`}
      close={() => {
        close();
      }}
      header={() => (
        <span className="precontractual-save-pdf precontractual-header" style={{fontSize: isMobile?"12px":"18px"}}>
          {target==="tnc" && <a><Trans>precontractual.tnc</Trans></a>}
          {target==="privacy" && <Trans>precontractual.otherinfo</Trans>}
          {target==="tou" && <Trans>precontractual.tou</Trans>}
          {target==="amtrust" && <Trans>precontractual.amtrust</Trans>}
        </span>
      )}
    >
      <div className="precontractual-dialog-content">
        {target==="tnc" && <div>
          <TermsAndConditionsComponent />
        </div>}
        {target==="privacy" && <div>
          <OtherImportantInformationComponent />
        </div>}
        {target==="tou" && <div>
          {/*<TermsOfUseComponent />*/}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
            <Viewer fileUrl={getToU} />
          </Worker>
        </div>}
        {target==="amtrust" && <div>
          {/*<TermsOfUseComponent />*/}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
            <Viewer fileUrl={getAmtrust} />
          </Worker>
        </div>}
      </div>
    </DialogComponent>
  );
};
