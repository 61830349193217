import React, {useContext, useState} from 'react';
import * as constants from "../../app.constant";
import translateArrow from '../../assets/images/translate_arrow.png';
import TranslateModalComponent from "./translateModal.component";
import {Trans, I18nextContext} from "gatsby-plugin-react-i18next";
import { PrecontractualTouPopup } from '../landing/PrecontractualPopup';

export default function Footer( { isMobile = false, isTablet = false } = {}) {
  const [translateClassName1, setTranslateClassName1] = useState('bottom-slide-in-custom-modal-hidden')
  const [translateClassName2, setTranslateClassName2] = useState('slide-out')
  const [isVisible, setIsVisible] = useState(false)
  const [showPrecontract, setPrecontract] = useState(false)
  const [showDisplayPopUp, setDisplayPopUp] = useState("tnc")
  const { language } = useContext(I18nextContext)
  const translateTextShort = constants.TRANSLATE_SHORT[language]

  const showTranslate = (e) => {
    if((e.target.nodeName!=="H4" && e.target.nodeName==="A") || (e.target.nodeName==="SPAN" && e.target.className!=="grey-box") || e.target.className==="bottom-slide-in-custom-modal"){
      if(translateClassName2=== 'slide-in'){

          setTranslateClassName1('bottom-slide-in-custom-modal-hidden')
          setTranslateClassName2('slide-out')
          setIsVisible(false)

      }else{
          setTranslateClassName1('bottom-slide-in-custom-modal')
          setTranslateClassName2('slide-in')
          setIsVisible(true)
      }
    }
  }

  const handleClick = (param) => {
    setDisplayPopUp(param)
    setPrecontract(true)
  }

  return (
    <>
      <PrecontractualTouPopup
        target={showDisplayPopUp}
        show={showPrecontract}
        close={() => setPrecontract(false)}
      />
      {isMobile && <div className={"container-responsive footer mobile"} style={{marginBottom:window.location.pathname.includes("personal-info")?'25%':'24px'}}>
        <p className="text-grey"><Trans>footer.label1</Trans> <br/><Trans>footer.label2</Trans></p>
        {/*<div >
          <span className="grey-box">{translateTextShort.toUpperCase()}</span>
          <span onClick={showTranslate} style={{cursor:'pointer', fontSize:'12px'}}><Trans>language.{language}</Trans> <img src={translateArrow} alt=""/></span>
        </div>*/}
        <div>
        <strong><a onClick={() => handleClick("tou")}><Trans>footer.label3</Trans></a></strong>
        </div>
      </div>}
      {!isMobile && <div className="footer desktop">
        <div className="footer-container">
            <div className="left-container float-left">
              <span className="text-grey"><Trans>footer.label1</Trans> <Trans>footer.label2</Trans></span>
              <div>
                <a onClick={() => handleClick("tou")} style={{cursor:'pointer'}}><strong><Trans>footer.label3</Trans></strong></a>
              </div>
            </div>
            {/*<div className="right-container" style={{marginRight: isTablet?'0':'1%', fontSize:'14px'}}>
              <span className="grey-box">{translateTextShort.toUpperCase()}</span>
              <span onClick={showTranslate} style={{cursor:'pointer'}}><Trans>language.{language}</Trans> <img src={translateArrow} alt=""/></span>
            </div>*/}
        </div>
      </div>}

      {isVisible && <div className='text-left'>
        {<TranslateModalComponent onShowTranslate={showTranslate} translateClassName1={translateClassName1} translateClassName2={translateClassName2}/>}
      </div>}
    </>
  )
}