import React, {useContext, useEffect, useState} from 'react';
import * as constants from '../../app.constant';
import {I18nextContext, Link, Trans, useI18next} from "gatsby-plugin-react-i18next"
import samsung_logo_header from '../../assets/images/samsung_logo_header.svg';
import bolttech_logo_header from '../../assets/images/bolttech_logo_header.svg';
import translateArrow from "../../assets/images/translate_arrow.svg";
import {env, TRANSLATE_SHORT} from "../../app.constant";

const Header = ({prevUrl, isBackBtn, isLanding}) => {
  const { navigate } = useI18next()
  const [isMobile, setMobile] = useState(false)
  const [isTablet, setTablet] = useState(false)
  const { language } = useContext(I18nextContext)
  const {originalPath} = useI18next();
  const translateTextShort = constants.TRANSLATE_SHORT[language]
  
  
  useEffect(() => {
     setMobile(constants.isMobile())
     setTablet(constants.isTablet())
  }, [])

  const goToBackPage = () => {
    if (prevUrl) {
      navigate(prevUrl);
    } else {
      window.history.back();
    }
  }

  const onDropdown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  const reloadOTBanner = (lang) => {
    let otConsentSdk = document.getElementById('onetrust-consent-sdk');
    if (otConsentSdk) {
      otConsentSdk.remove();
    }
    if (window.OneTrust != null) {
      window.OneTrust.Init();
      setTimeout(() => {
        window.OneTrust.LoadBanner();
        let toggleDisplay = document.getElementsByClassName(
          'ot-sdk-show-settings',
        );
        if (window.OneTrust) {
          window.OneTrust.changeLanguage(lang);
        }
        for (const elem of toggleDisplay) {
          elem.onclick = (event) => {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          };
        }
      }, 1000);
    }
  }
  
  return (
    <>
      <div className={`header ${isBackBtn&&isMobile ? "header-mobile" : !isBackBtn&&(isMobile || isTablet)?"header-mobile-no-btn":""}`}>
        <div className="header-container" >
          <div className="logo-container">
            <div className="show-samsungLogo">
              <img src={samsung_logo_header} alt="" width="100%" />
            </div>
            <div className="show-bolttechLogo">
              <img src={bolttech_logo_header} alt="" width="100%" />
            </div>
          </div>
          <div>
            <div className="dropdown">
              <button onClick={onDropdown} className="dropbtn">{translateTextShort.toUpperCase()} <img src={translateArrow} alt=""/></button>
              <div id="myDropdown" className="dropdown-content">
                {env.LANGUAGES.map((lng) => (
                  <div className="separator" key={lng}>
                    <Link onClick={() => reloadOTBanner(TRANSLATE_SHORT[lng])} to={originalPath} language={lng}>
                      {TRANSLATE_SHORT[lng]}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {(isMobile || isTablet) && isBackBtn &&(
          <div>
            {/* <div className="border-bottom-line"/> */}
            <div className="backBtnFlexMobile">
              <div className="backBtn" onClick={goToBackPage}/>
              <span><Trans>header.back</Trans></span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Header;