import React from 'react';
import Loader from 'react-loader-spinner'

const LoadingSpinner = props => {
    return ((props.isVisible) &&
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: "9999",
            backgroundColor: "rgba(211,211,211,0.4)",
        }}>
            <Loader
                type="Rings"
                color="#000"
                height={100}
                width={100}
            />
        </div>)
}

export default LoadingSpinner;