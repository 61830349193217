import * as constants from './app.constant';
import DeviceFingerprint from "@device/fingerprint";
import dfpStore from './store/dfpStore';
import planStore from './store/planStore';
import deviceStore from './store/deviceStore';

let dataLayer;
if (typeof window !== 'undefined') {
  dataLayer = window.dataLayer || [];
}

export const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

const tokenObj = {
  access_token: "",
  expires_in: 0,
  updatedAt: null,
  get isExpired() {
    if (this.updatedAt === null || this.expires_in === 0) return true;
    if ((this.updatedAt.valueOf() + this.expires_in * 1000) > new Date().valueOf()) {
      return false;
    }
    return true
  }
}

export const getOrderToken = (force = false) => {

  return new Promise(resolve => {
    /*if (!force && !tokenObj.isExpired) {
      resolve(tokenObj.access_token);
      return
    }*/

    const request_url = constants.env.IDENTITY_SERVICE_URL;
    const method_options = {
      method: 'POST',
      headers: {
        'Content-Type': constants.HEADER_REQUEST.contentType,
        'bolt-country-code': constants.env.GATSBY_COUNTRY_CODE,
        'bolt-tenant-id': constants.env.GATSBY_TENANT_ID,
      },
      body: JSON.stringify({
        "client_id": constants.env.GATSBY_CLIENT_ID,
        "client_secret": constants.env.GATSBY_CLIENT_SECRET,
        "grant_type": constants.env.GATSBY_GRANT_TYPE
      })
    }
    fetch(request_url, method_options)
      .then(res => res.json())
      .then(response => {
        tokenObj.updatedAt = new Date();
        tokenObj.expires_in = response.expires_in;
        tokenObj.access_token = response.access_token;
        resolve(response.access_token);
      })
      .catch(() => resolve(null))
  })
}

export const pushPageViewEventToGA = () => {
  dataLayer.push({ event: 'pageView' });
}

export const sendDataToGoogleAnalytics = (eventName, order_status, payment_status) => {
  dataLayer.push({
    'event': eventName,
    'ecommerce': {
      transaction_id: planStore.orderNo,
      value: planStore.fulfillmentSystemPrice,
      currency: planStore.currency,
      order_status: order_status,
      payment_status: payment_status,
      items: [{
        item_id: planStore.productId,
        item_name: planStore.productName,
        price: planStore.fulfillmentSystemPrice,
        device: deviceStore.deviceModelDesc + ' ' + deviceStore.deviceModel,
        currency: planStore.currency
      }]
    }
  });
}

const deviceFingerPrintConfig = (email, phone, imei, googleVisitorId) => {
  let deviceFingerprintConfig = {
    deviceInsightUrl: constants.env.DEVICE_INSIGHT_URL,
    tenantId: constants.env.GATSBY_TENANT_ID,
    providers: {
      fingerprintJs: {
        scriptUrl: constants.env.FINGER_PRINT_SCRIPT_URL,
        accessToken: constants.env.FINGER_PRINT_SCRIPT_TOKEN
      },
      dataVisor: {
        accessKey: constants.env.DV_ACCESS_KEY,
        environment: constants.env.DV_ENVIRONMENT,
        scriptUrl: constants.env.DV_SCRIPT_URL
      },
      seon: {
        scriptUrl: constants.env.SEON_SCRIPT_URL,
        accessToken: constants.env.SEON_ACCESS_TOKEN
      }
    }
  };
  let guestDetail = { email, imei, phone, googleVisitorId };
  let deviceFingerprint = new DeviceFingerprint(deviceFingerprintConfig);
  return {
    guestDetail,
    deviceFingerprint
  }
}

/*
  @method: initFingerPrintSDK
  @description: Init DFP SDK
*/
export const initFingerPrintSDK = (email, phone, imei) => {
  const { guestDetail, deviceFingerprint } = deviceFingerPrintConfig(email, phone, imei);
  deviceFingerprint.initDeviceFingerprint(guestDetail).then(res => {
    dfpStore.updateGuestId(res.data);
    if(window !== undefined && window.gaGlobal !== undefined){
      dfpStore.updateGoogleVisitorId(true); //
      updateGuestDetailsInDFP(null, null, null, res.data, window.gaGlobal.vid); //update DFP guest details if google visitor id exist
    }else{
      dfpStore.updateGoogleVisitorId(false);
    }
  }).catch(err => {
    console.log(err);
  });
}

/*
  @method: updateGuestDetailsInDFP
  @description: Update DFP guest details
*/
export const updateGuestDetailsInDFP = (email, phone, imei, guestId, googleVisitorId) => {
  const { guestDetail, deviceFingerprint } = deviceFingerPrintConfig(email, phone, imei, googleVisitorId);
  deviceFingerprint.updateGuestDetails(guestId, guestDetail).then(res => {
    console.log(res);
  }).catch(err => {
    console.log(err);
  });
}

export const handleAmount = (amount = "0") => {
  return parseFloat(amount).toFixed(2).replace(".00", "")
}

export const trackTiming = (deviceUniqueId, date, event) => {
  let diffInSeconds = ((new Date()).getTime() - date.getTime()) / 1000;
  if (diffInSeconds >= constants.env.TIME_SECONDS_TAKEN_TRESHOLD) {
    sendLongPageLoadingEmail(deviceUniqueId, diffInSeconds, event)
  }
}

async function sendLongPageLoadingEmail(deviceUniqueId, timeLapseInSeconds, event) {
  try {
    return await fetch(`${constants.env.SEND_EXCEPTION_EMAIL_URL}`, {
      method: "POST",
      headers: {
        "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        partnerId: constants.env.GATSBY_PARTNER_CODE,
        deviceUniqueId: deviceUniqueId,
        type: "d2cLongPageLoading",
        event: event,
        timeLapse: (timeLapseInSeconds.toString() + "s")
      })
    })
  } catch (error) {
    console.log('sendLongPageLoadingEmail API error', error)
    return null
  }
}