import React from 'react';
import faildIcon from '../../assets/images/network_error_icon.svg';
import loaderStore from '../../store/loaderStore';

const NetworkErrorComponent = () => {
  const pageReferesh = () =>{
    loaderStore.showLoader(true);
    setTimeout(()=>{
      loaderStore.showLoader(false);
    },1000);
  }
  return (
    <div className="error-page">
        <div className="container">
          <h2><img src={faildIcon} alt="" width="45" /></h2>
          <h2>You’re not connected to the<br />internet</h2>
          <p>Please check your internet connection or<br />move closer to your router</p>
        </div>
        <div className="footer-fixed">
          <div className="container text-center">
            <button type="button" className="btn btn-block" onClick={()=>pageReferesh()}>TRY AGAIN</button>
          </div>
        </div>
    </div>
  )
}

export default NetworkErrorComponent;