import React, { Component } from "react"
import { Trans } from "gatsby-plugin-react-i18next"
import { env } from "../../app.constant"

class OtherImportantInformationComponent extends Component {
  render() {
    return (
      <div className="tnc-section pt-65">
        <div className="container-responsive privacy-policy-wrapper">
          <div className="title">
            <h4>
              <Trans>docs.other.section1.title</Trans>
            </h4>
          </div>
          <ol className="nested parent">
            {/*1*/}
            <li>
              <strong>
                <Trans>docs.other.section1.label1</Trans>
              </strong>
              <p>
                <Trans>docs.other.section1.label2</Trans>
              </p>
            </li>
            {/*2*/}
            <li>
              <strong>
                <Trans>docs.other.section1.label3</Trans>
              </strong>
              <p>
                <Trans
                  components={{
                    1: (
                      <a
                        href="https://www.bmaw.gv.at/en/Topics/Enterprise/InsuranceMediation.html"
                        tabIndex="-1"
                      />
                    ),
                  }}
                >
                  docs.other.section1.label4
                </Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label5</Trans>
              </p>
            </li>
            {/*3*/}
            <li>
              <strong>
                <Trans>docs.other.section1.label6</Trans>
              </strong>
              <p>
                <Trans components={{u: <u/>}}>docs.other.section1.label7</Trans>
                <Trans>docs.other.section1.label7-1</Trans>
              </p>
              <p>
                <Trans components={{u: <u/>}}>docs.other.section1.label8</Trans>
                <Trans>docs.other.section1.label8-1</Trans>
              </p>
            </li>
            {/*4*/}
            {env.GATSBY_COUNTRY === "BE" && (
              <li>
                <strong>
                  <Trans>docs.other.section1.label50</Trans>
                </strong>
                <p>
                  <Trans>docs.other.section1.label51</Trans>
                </p>
              </li>
            )}
            <li className="table-border-all">
              <strong>
                <Trans>docs.other.section1.label9</Trans>
              </strong>
              <p>
                <Trans>docs.other.section1.label10</Trans>
              </p>
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label11</Trans>
                    </th>
                    {env.GATSBY_COUNTRY === "BE" && (
                      <td>
                        <a href="https://be.careplus.co" tabIndex="-1">
                          https://be.careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "NL" && (
                      <td>
                        <a href="https://nl.careplus.co" tabIndex="-1">
                          https://nl.careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "FI" && (
                      <td>
                        <a href="https://fi.careplus.co" tabIndex="-1">
                          https://fi.careplus.co
                        </a>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label12</Trans>
                    </th>
                    {env.GATSBY_COUNTRY === "BE" && (
                      <td>
                        <a
                          href="mailto:complaints.be@careplus.co"
                          tabIndex="-1"
                        >
                          complaints.be@careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "NL" && (
                      <td>
                        <a
                          href="mailto:complaints.nl@careplus.co"
                          tabIndex="-1"
                        >
                          complaints.nl@careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "FI" && (
                      <td>
                        <a
                          href="mailto:complaints.fi@careplus.co"
                          tabIndex="-1"
                        >
                          complaints.fi@careplus.co
                        </a>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label13</Trans>
                    </th>
                    <td>
                      <Trans>docs.other.section1.label13-1</Trans>
                    </td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label14</Trans>
                    </th>
                    <td>
                      <Trans>docs.other.section1.label14-1</Trans>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="pt-20">
                <Trans>docs.other.section1.label15</Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label16</Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label17</Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label18</Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label19</Trans>
              </p>
              {env.GATSBY_COUNTRY === "FI" && (
                <>
                  <table style={{width:"100%"}}>
                    <tbody>
                      <tr>
                        <th className="pr-10">
                          <Trans>docs.other.section1.label14</Trans>
                        </th>
                        <td>+358 29 505 3050</td>
                      </tr>
                      <tr>
                        <th className="pr-10">
                          <Trans>docs.other.section1.label21</Trans>
                        </th>
                        <td style={{wordBreak: "break-word"}}>
                          <a
                            href="https://www.kkv.fi/kuluttajaneuvonta"
                            tabIndex="-1"
                          >
                            https://www.kkv.fi/kuluttajaneuvonta
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <label>Kuluttajariitalautakunnan yhteystiedot:</label>
                  <br />
                  <br />
                </>
              )}
              <table style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <th className="pr-10 vertical-align-top">
                      <Trans>docs.other.section1.label20</Trans>
                    </th>
                    {env.GATSBY_COUNTRY === "BE" && (
                      <td>
                        <Trans>docs.other.section1.label20-1</Trans>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "NL" && (
                      <td>
                        Klachteninstituut Financiële Dienstverlening, Postbus
                        93257, 2509 AG, The Hague
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "FI" && (
                      <td>
                        Kuluttajariitalautakunta Hämeentie 3 PL 3066 00531
                        Helsinki
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label14</Trans>
                    </th>
                    {env.GATSBY_COUNTRY === "BE" && <td>+32 2 547 58 71</td>}
                    {env.GATSBY_COUNTRY === "NL" && <td>+31 70-333 8 999</td>}
                    {env.GATSBY_COUNTRY === "FI" && <td>+358 29 566 5200</td>}
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label12</Trans>
                    </th>
                    <td>
                      {env.GATSBY_COUNTRY === "BE" && (
                        <a href="mailto:info@ombudsman.as" tabIndex="-1">
                          info@ombudsman.as
                        </a>
                      )}
                      {env.GATSBY_COUNTRY === "NL" && (
                        <a href="mailto:consumenten@kifid.nl" tabIndex="-1">
                          consumenten@kifid.nl
                        </a>
                      )}
                      {env.GATSBY_COUNTRY === "FI" && (
                        <a href="mailto:krill@oikeus.fi" tabIndex="-1">
                          krill@oikeus.fi
                        </a>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label21</Trans>
                    </th>
                    <td>
                      {env.GATSBY_COUNTRY === "BE" && (
                        <a href="https://www.ombudsman.as" tabIndex="-1">
                          https://www.ombudsman.as
                        </a>
                      )}
                      {env.GATSBY_COUNTRY === "NL" && (
                        <a href="https://www.kifid.nl/" tabIndex="-1">
                          https://www.kifid.nl/
                        </a>
                      )}
                      {env.GATSBY_COUNTRY === "FI" && (
                        <a href="https://www.kuluttajariita.fi" tabIndex="-1">
                          https://www.kuluttajariita.fi
                        </a>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="pt-20">
                <Trans components={{u: <u/>}}>docs.other.section1.label22</Trans>
                <Trans>docs.other.section1.label22-1</Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label23</Trans>
              </p>
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <th className="pr-10 vertical-align-top">
                      <Trans>docs.other.section1.label20</Trans>
                    </th>
                    <td>
                      Beschwerdestelle über Versicherungsvermittler im BMDW,
                      Bundesministerium für Digitalisierung und
                      Wirtschaftsstandort Stubenring 1, 1010 Vienna, Abteilung
                      IV/1, Austria
                    </td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label14</Trans>
                    </th>
                    <td>+43 1-71100/805782</td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label12</Trans>
                    </th>
                    <td>
                      <a
                        href="mailto:stefan.trojer@oesterreich.gv.at"
                        tabIndex="-1"
                      >
                        stefan.trojer@oesterreich.gv.at
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label21</Trans>
                    </th>
                    <td>
                      <a href="oesterreich.gv.at" tabIndex="-1">
                        oesterreich.gv.at
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p>
                <Trans>docs.other.section1.label24</Trans>
              </p>
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <th className="pr-10 vertical-align-top">
                      <Trans>docs.other.section1.label20</Trans>
                    </th>
                    <td>
                      <Trans>docs.other.section1.label19-1</Trans>
                    </td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label14</Trans>
                    </th>
                    <td>+353 1 567 7000</td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label12</Trans>
                    </th>
                    <td>
                      <a href="mailto:nfo@fspo.ie" tabIndex="-1">
                        nfo@fspo.ie
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="pr-10">
                      <Trans>docs.other.section1.label21</Trans>
                    </th>
                    <td>
                      <a href="www.fspo.ie" tabIndex="-1">
                        www.fspo.ie
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              {env.GATSBY_COUNTRY !== "FI" && (
                <p className="pt-20">
                  <Trans>docs.other.section1.label25</Trans>
                </p>
              )}
              <p>
                <Trans
                  components={{
                    1: (
                      <a
                        href="http://ec.europa.eu/consumers/odr/"
                        tabIndex="-1"
                      />
                    ),
                  }}
                >
                  docs.other.section1.label26
                </Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label27</Trans>
              </p>
            </li>
            {/*5*/}
            <li>
              <strong>
                <Trans>docs.other.section1.label28</Trans>
              </strong>
              <p>
                <Trans>docs.other.section1.label29</Trans>
              </p>
            </li>
            {/*6*/}
            <li className="table-border-all">
              <strong>
                <Trans>docs.other.section1.label30</Trans>
              </strong>
              <p>
                <u>
                  <Trans>docs.other.section1.label31</Trans>
                </u>
              </p>
              <p>
                <Trans>docs.other.section1.label32</Trans>
              </p>
              <ol className="lower-roman-parentheses">
                <li>
                  <Trans>docs.other.section1.label33</Trans>
                </li>
                <li>
                  <Trans>docs.other.section1.label34</Trans>
                </li>
                <li>
                  <Trans>docs.other.section1.label35</Trans>
                </li>
              </ol>
              <p>
                <Trans>docs.other.section1.label36</Trans>
              </p>
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <th className="pr-10">
                      {env.GATSBY_COUNTRY === "FI"? (
                        <Trans>docs.other.section1.onlineportal</Trans>
                      ):(
                        <Trans>docs.other.section1.label11</Trans>
                      )
                      }
                    </th>
                    {env.GATSBY_COUNTRY === "BE" && (
                      <td>
                        <a href="https://be.careplus.co" tabIndex="-1">
                          https://be.careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "NL" && (
                      <td>
                        <a href="https://nl.careplus.co" tabIndex="-1">
                          https://nl.careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "FI" && (
                      <td>
                        <a href="https://fi.careplus.co" tabIndex="-1">
                          https://fi.careplus.co
                        </a>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th className="pr-10">
                      {env.GATSBY_COUNTRY === "FI"? (
                        <Trans>docs.other.section1.e-post</Trans>
                      ):(
                        <Trans>docs.other.section1.label12</Trans>
                      )
                      }
                    </th>
                    {env.GATSBY_COUNTRY === "BE" && (
                      <td>
                        <a href="mailto:contact.be@careplus.co" tabIndex="-1">
                          contact.be@careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "NL" && (
                      <td>
                        <a href="mailto:contact.nl@careplus.co" tabIndex="-1">
                          contact.nl@careplus.co
                        </a>
                      </td>
                    )}
                    {env.GATSBY_COUNTRY === "FI" && (
                      <td>
                        <a href="mailto:contact.fi@careplus.co" tabIndex="-1">
                          contact.fi@careplus.co
                        </a>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th className="pr-10">
                      {env.GATSBY_COUNTRY === "FI"? (
                        <Trans>docs.other.section1.post</Trans>
                      ):(
                        <Trans>docs.other.section1.label13</Trans>
                      )
                      }
                    </th>
                    <td>
                      <Trans>docs.other.section1.label13-2</Trans>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p>
                <u>
                  <Trans>docs.other.section1.label37</Trans>
                </u>
              </p>
              <p>
                <Trans>docs.other.section1.label38</Trans>
              </p>
              <p>
                <Trans>docs.other.section1.label39</Trans>
              </p>
            </li>
            {/*7*/}
            <li className="table-border-all">
              <strong>
                <Trans>docs.other.section1.label40</Trans>
              </strong>
              <p>
                <Trans>docs.other.section1.label41</Trans>
              </p>

              <p>
                <strong>
                  <Trans>docs.other.section1.label42</Trans>
                </strong>
              </p>
              <table style={{ width: "100%", textAlign: "center", fontWeight: "700"}} className="device-price-table">
                <tbody>
                  <tr>
                    <th style={{width:"10%"}}>
                      <Trans>docs.other.section1.label43</Trans>
                    </th>
                    {env.GATSBY_COUNTRY === "FI" ? (
                            <td rowSpan="2"><Trans>docs.other.section1.label44</Trans></td>
                        ):
                        <td><Trans>docs.other.section1.label44</Trans></td>
                    }
                    <th>
                      <Trans>docs.other.section1.label45</Trans>
                    </th>
                    <th>
                      <Trans>docs.other.section1.label46</Trans>
                    </th>
                  </tr>
                  <tr>
                    <td>1</td>
                    {env.GATSBY_COUNTRY === "FI" && (
                        <td rowSpan="2"><Trans>docs.other.table.non-flagship</Trans></td>
                    )}
                    <td><Trans>docs.other.table.smartphone.row1.column1</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row1.column2</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row1.column3</Trans></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td><Trans>docs.other.table.smartphone.row2.column1</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row2.column2</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row2.column3</Trans></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    {env.GATSBY_COUNTRY === "FI" && (
                        <td rowSpan="2"><Trans>docs.other.table.flagship</Trans></td>
                    )}
                    <td><Trans>docs.other.table.smartphone.row3.column1</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row3.column2</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row3.column3</Trans></td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td><Trans>docs.other.table.smartphone.row4.column1</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row4.column2</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row4.column3</Trans></td>
                  </tr>
                  <tr>
                    <td>5</td>
                    {env.GATSBY_COUNTRY === "FI" && (
                        <td><Trans>docs.other.table.fold</Trans></td>
                    )}
                    <td><Trans>docs.other.table.smartphone.row5.column1</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row5.column2</Trans></td>
                    <td><Trans>docs.other.table.smartphone.row5.column3</Trans></td>
                  </tr>
                </tbody>
              </table>
              {(env.GATSBY_COUNTRY !== "FI" && env.GATSBY_COUNTRY !== "NL") && (
                <p>
                  <Trans>docs.other.section1.label47</Trans>
                </p>
              )}
              {(env.GATSBY_COUNTRY === "FI" || env.GATSBY_COUNTRY === "NL")  && (
                <br />
              )}
              <p>
                <strong>
                  <Trans>docs.other.section1.label48</Trans>
                </strong>
              </p>
              <table style={{ width: "100%", textAlign: "center", fontWeight: "700" }} className="device-price-table">
                <tbody>
                  <tr>
                    <th>
                      <Trans>docs.other.section1.label43</Trans>
                    </th>
                    {env.GATSBY_COUNTRY === "FI" ? (
                        <th colSpan="2">
                          <Trans>docs.other.section1.label44</Trans>
                        </th>
                    ): <th><Trans>docs.other.section1.label44</Trans></th>}

                    <th>
                      <Trans>docs.other.section1.label45</Trans>
                    </th>
                    <th>
                      <Trans>docs.other.section1.label46</Trans>
                    </th>
                  </tr>
                  <tr>
                    <td>1</td>
                    {env.GATSBY_COUNTRY === "FI"  && (
                        <td rowSpan="2"><Trans>docs.other.table.non-flagship</Trans></td>
                    )}
                    <td><Trans>docs.other.table.tablet.row1.column1</Trans></td>
                    <td><Trans>docs.other.table.tablet.row1.column2</Trans></td>
                    <td><Trans>docs.other.table.tablet.row1.column3</Trans></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td><Trans>docs.other.table.tablet.row2.column1</Trans></td>
                    <td><Trans>docs.other.table.tablet.row2.column2</Trans></td>
                    <td><Trans>docs.other.table.tablet.row2.column3</Trans></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    {env.GATSBY_COUNTRY === "FI"  && (
                        <td><Trans>docs.other.table.flagship</Trans></td>
                    )}
                    <td><Trans>docs.other.table.tablet.row3.column1</Trans></td>
                    <td><Trans>docs.other.table.tablet.row3.column2</Trans></td>
                    <td><Trans>docs.other.table.tablet.row3.column3</Trans></td>
                  </tr>
                </tbody>
              </table>
              {(env.GATSBY_COUNTRY !== "FI" && env.GATSBY_COUNTRY !== "NL")  && (
                <p>
                  <Trans>docs.other.section1.label47</Trans>
                </p>
              )}
              {(env.GATSBY_COUNTRY === "FI" || env.GATSBY_COUNTRY === "NL") && (
                <br />
              )}
              {env.GATSBY_COUNTRY === "BE" && (
                  <>
                    <p>
                      <strong>
                        <Trans>docs.other.section1.label52</Trans>
                      </strong>
                    </p>
                    <table style={{ width: "100%", textAlign: "center", fontWeight: "700" }} className="device-price-table">
                      <tbody>
                      <tr>
                        <th>
                          <Trans>docs.other.section1.label43</Trans>
                        </th>
                        <th>
                          <Trans>docs.other.section1.label44</Trans>
                        </th>
                        <th>
                          <Trans>docs.other.section1.label45</Trans>
                        </th>
                        <th>
                          <Trans>docs.other.section1.label46</Trans>
                        </th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td><Trans>docs.other.table.laptop.row1.column1</Trans></td>
                        <td><Trans>docs.other.table.laptop.row1.column2</Trans></td>
                        <td><Trans>docs.other.table.laptop.row1.column3</Trans></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td><Trans>docs.other.table.laptop.row2.column1</Trans></td>
                        <td><Trans>docs.other.table.laptop.row2.column2</Trans></td>
                        <td><Trans>docs.other.table.laptop.row2.column3</Trans></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td><Trans>docs.other.table.laptop.row3.column1</Trans></td>
                        <td><Trans>docs.other.table.laptop.row3.column2</Trans></td>
                        <td><Trans>docs.other.table.laptop.row3.column3</Trans></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td><Trans>docs.other.table.laptop.row4.column1</Trans></td>
                        <td><Trans>docs.other.table.laptop.row4.column2</Trans></td>
                        <td><Trans>docs.other.table.laptop.row4.column3</Trans></td>
                      </tr>
                      </tbody>
                    </table>
                    <p>
                      <Trans>docs.other.section1.label47</Trans>
                    </p>
                  </>
              )}
              <p>
                <strong>
                  <Trans>docs.other.section1.label49</Trans>
                </strong>
              </p>
              <table style={{ width: "100%", textAlign: "center", fontWeight: "700" }} className="device-price-table">
                <tbody>
                  <tr>
                    <th>
                      <Trans>docs.other.section1.label43</Trans>
                    </th>
                    <th>
                      <Trans>docs.other.section1.label44</Trans>
                    </th>
                    <th>
                      <Trans>docs.other.section1.label45</Trans>
                    </th>
                    <th>
                      <Trans>docs.other.section1.label46</Trans>
                    </th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td><Trans>docs.other.table.watch.1</Trans></td>
                    <td><Trans>docs.other.table.wearable.row1.column1</Trans></td>
                    <td><Trans>docs.other.table.wearable.row1.column2</Trans></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td><Trans>docs.other.table.watch.2</Trans></td>
                    <td><Trans>docs.other.table.wearable.row2.column1</Trans></td>
                    <td><Trans>docs.other.table.wearable.row2.column2</Trans></td>
                  </tr>
                </tbody>
              </table>
              {(env.GATSBY_COUNTRY !== "FI" && env.GATSBY_COUNTRY !== "NL")  && (
                <p>
                  <Trans>docs.other.section1.label47</Trans>
                </p>
              )}
              {(env.GATSBY_COUNTRY === "FI" || env.GATSBY_COUNTRY === "NL") && (
                <br />
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                  <>
                  <p>
                    <strong>
                      <Trans>docs.other.section1.label52</Trans>
                    </strong>
                  </p>
                <table style={{ width: "100%", textAlign: "center", fontWeight: "700" }} className="device-price-table">
                <tbody>
                <tr>
                <th>
                <Trans>docs.other.section1.label43</Trans>
                </th>
                <th>
                <Trans>docs.other.section1.label44</Trans>
                </th>
                <th>
                <Trans>docs.other.section1.label45</Trans>
                </th>
                <th>
                <Trans>docs.other.section1.label46</Trans>
                </th>
                </tr>
                <tr>
                <td>1</td>
                <td><Trans>docs.other.table.laptop.row1.column1</Trans></td>
                <td><Trans>docs.other.table.laptop.row1.column2</Trans></td>
                <td><Trans>docs.other.table.laptop.row1.column3</Trans></td>
                </tr>
                <tr>
                <td>2</td>
                <td><Trans>docs.other.table.laptop.row2.column1</Trans></td>
                <td><Trans>docs.other.table.laptop.row2.column2</Trans></td>
                <td><Trans>docs.other.table.laptop.row2.column3</Trans></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td><Trans>docs.other.table.laptop.row3.column1</Trans></td>
                  <td><Trans>docs.other.table.laptop.row3.column2</Trans></td>
                  <td><Trans>docs.other.table.laptop.row3.column3</Trans></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td><Trans>docs.other.table.laptop.row4.column1</Trans></td>
                  <td><Trans>docs.other.table.laptop.row4.column2</Trans></td>
                  <td><Trans>docs.other.table.laptop.row4.column3</Trans></td>
                </tr>
                </tbody>
                </table>
                <p>
                <Trans>docs.other.section1.label47</Trans>
                </p>
                  </>
                )}
            </li>
          </ol>

          <div className="title">
            <h4>
              <Trans>docs.other.section2.title</Trans>
            </h4>
          </div>
          <p>
            <strong>
              <Trans>docs.other.section2.label1</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label2</Trans>
          </p>
          <p>
            <Trans>docs.other.section2.label3</Trans>
          </p>
          <p>
            <Trans>docs.other.section2.label4</Trans>
          </p>
          <p>
            <Trans>docs.other.section2.label5</Trans>
          </p>
          <p>
            <strong>
              <Trans>docs.other.section2.label6</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label7</Trans>
          </p>
          <div className="table-border-all">
            <table style={{width:"100%"}}>
              <tbody>
                <tr>
                  <th className="pr-10 vertical-align-top text-left">
                    <Trans>docs.other.section2.label8</Trans>
                  </th>
                  <td>
                    <Trans>docs.other.section2.label9</Trans>
                  </td>
                </tr>
                <tr>
                  <th className="pr-10 vertical-align-top text-left">
                    <Trans>docs.other.section2.label10</Trans>
                  </th>
                  <td>
                    <Trans>docs.other.section2.label11</Trans>
                  </td>
                </tr>
                <tr>
                  <th className="pr-10 vertical-align-top text-left">
                    <Trans>docs.other.section2.label12</Trans>
                  </th>
                  <td>
                    <Trans>docs.other.section2.label13</Trans>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
          <p>
            <strong>
              <Trans>docs.other.section2.label14</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label15</Trans>
          </p>
          <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }} className="device-price-table">
            <li>
              <Trans>docs.other.section2.label16</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label17</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label18</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label19</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label20</Trans>
            </li>
          </ol>
          <p>
            <strong>
              <Trans>docs.other.section2.label21</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label22</Trans>
          </p>
          <ul>
            <li>
              <Trans>docs.other.section2.label23</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label24</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label25</Trans>
            </li>
          </ul>
          <p>
            <Trans>docs.other.section2.label26</Trans>
          </p>
          <ul>
            <li>
              <Trans>docs.other.section2.label27</Trans>
            </li>
            <li>
              <Trans>docs.other.section2.label28</Trans>
            </li>
          </ul>
          <p>
            <Trans>docs.other.section2.label29</Trans>
          </p>
          <p>
            <strong>
              <Trans>docs.other.section2.label30</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label31</Trans>
          </p>
          <p>
            <strong>
              <Trans>docs.other.section2.label32</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label33</Trans>
          </p>
          <p>
            <Trans>docs.other.section2.label34</Trans>
          </p>
          <p>
            <Trans>docs.other.section2.label35</Trans>
          </p>
          <p>
            <strong>
              <Trans>docs.other.section2.label36</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label37</Trans>
            {env.GATSBY_COUNTRY !== "FI"&& (
                <a href="mailto:privacy@bolttech.eu">privacy@bolttech.eu.</a>
            )}
          </p>
          <p>
            <Trans
                components={{
                  1: (
                      <a
                          href="https://www.bmaw.gv.at/en/Topics/Enterprise/InsuranceMediation.html"
                          tabIndex="-1"
                      />
                  ),
                }}
            >
              docs.other.section2.label38
            </Trans>
          </p>
          <p>
            <strong>
              <Trans>docs.other.section2.label39</Trans>
            </strong>
          </p>
          <p>
            <Trans>docs.other.section2.label40</Trans>
          </p>
        </div>
      </div>
    )
  }
}

export default OtherImportantInformationComponent
