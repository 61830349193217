import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist';

class DfpStore {
    guestId = '';
    isGoogleVisitorIdExist = false;
    updateGuestId = (guestId) =>{
        this.guestId = guestId;
    }
    updateGoogleVisitorId = (id) =>{
        this.isGoogleVisitorIdExist = id;
    }
}

decorate(DfpStore, {
    guestId: [persist, observable],
    isGoogleVisitorIdExist: [persist, observable],
    updateGuestId:action,
    updateGoogleVisitorId:action
})
var dfpStore = new DfpStore();
export default dfpStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('dfpStore', dfpStore);
}
