import React from 'react';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {env, TRANSLATE_SHORT} from "../../app.constant";

const TranslateModalComponent = (props) => {
  const {originalPath} = useI18next();


  const reloadOTBanner = (lang) => {
    let otConsentSdk = document.getElementById('onetrust-consent-sdk');
    if (otConsentSdk) {
      otConsentSdk.remove();
    }
    if (window.OneTrust != null) {
      window.OneTrust.Init();
      setTimeout(() => {
        window.OneTrust.LoadBanner();
        let toggleDisplay = document.getElementsByClassName(
            'ot-sdk-show-settings',
        );
        if (window.OneTrust) {
          window.OneTrust.changeLanguage(lang);
        }
        for (const elem of toggleDisplay) {
          elem.onclick = (event) => {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          };
        }
      }, 1000);
    }
  }

    return (
    <>
      <div className={props.translateClassName1} onClick={props.onShowTranslate}>
        <div className={"display-12 modal-radius modal-wrapper " + props.translateClassName2}>
          <h4 className="pt-20 mb-20"><Trans>language.title</Trans></h4>
          {env.LANGUAGES.map((lng) => (
            <div className="mb-20" key={lng}>
              <span className="grey-box">{TRANSLATE_SHORT[lng]}</span>
              <Link onClick={() => reloadOTBanner(TRANSLATE_SHORT[lng])} to={originalPath} language={lng}>
                <Trans>language.{lng}</Trans>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TranslateModalComponent;

