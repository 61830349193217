import React, { useContext } from "react"
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next"
import { env } from "../../app.constant"

export default function TermsAndConditionsComponent({isMonthly}) {
  const { language } = useContext(I18nextContext)

  /* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
  return (
    <div className="insurance_MD">
      <p className="display-18" style={{ paddingBottom: "10px" }}>
        <span className="section-tnc"><Trans>docs.tnc.title</Trans></span>
      </p>
      <div className="tnc-intro display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.1.title</Trans>
        </p>
        <p style={{ lineHeight: "18px" }}>
          <Trans>docs.tnc.section.1.label1</Trans>
        </p>
        <span style={{ fontSize: "16px", lineHeight: "18px" }}>
          <Trans>docs.tnc.section.1.label2</Trans>
        </span>
        <br />
        <ol className="lower-roman-parentheses">
          <li>
            <Trans>docs.tnc.section.1.label3</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.1.label4</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.1.label5</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.1.label6</Trans>
          </li>
        </ol>
        <div>
          <p className={env.GATSBY_COUNTRY !== 'BE'?"underline_font":""}>
            <Trans>docs.tnc.section.1.label7</Trans>
          </p>
          <p>
            <Trans>docs.tnc.section.1.label8</Trans>
          </p>
          {isMonthly === true && (
              <p className="frame">
                <Trans components={{ u: <u /> }}>docs.tnc.section.1.label9</Trans>
              </p>
          )}
        </div>
      </div>

      <div className="def-terms paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.2.title</Trans>
        </p>
        <p style={{ lineHeight: "18px" }}>
          <Trans>docs.tnc.section.2.label1</Trans>
        </p>
        {language === "en" && (
          <div>
            <label>
              <Trans>docs.tnc.section.2.label2</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label3</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label4</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label5</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label6</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label7</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label8</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label9</Trans>
            </label>
            <br />
            <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label10</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label11</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label12</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label13</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label14</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label15</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label16</Trans>{" "}
              <a
                href="https://www.centralbank.ie"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.centralbank.ie
              </a>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label17</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label18</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label19</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label20</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label21</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label22</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label23</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label24</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label25</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label26</Trans>
            </label>
            <br />
            <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label27</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label28</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label29</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label30</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label31</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label32</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label33</Trans>
            </label>
            <br />
          </div>
        )}
        {language === "be_fr" && (
          <div>
            <label>
              <Trans>docs.tnc.section.2.label2</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label3</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label4</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label5</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label6</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label7</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label8</Trans>{" "}
              <a
                href="https://www.centralbank.ie"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.centralbank.ie
              </a>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label9</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label10</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label11</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label12</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label13</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label14</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label15</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label16</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label17</Trans>
            </label>
            <br />
            <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label18</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label19</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label20</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label21</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label22</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label23</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label24</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label25</Trans>
            </label>
            <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label26</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label27</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label28</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label29</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label30</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label31</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label32</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label33</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label34</Trans>
            </label>
            <br />
          </div>
        )}
        {(language === "be_nl" || language === "nl_nl") && (
          <div>
            <label>
              <Trans>docs.tnc.section.2.label6</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label9</Trans>
            </label>
            <br />
            <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label10</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label11</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label26</Trans>
            </label>
            <br />
            <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label27</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label28</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label29</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label30</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label13</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label12</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label25</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label7</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label8</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label14</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label23</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label34</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label2</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label3</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label4</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label19</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label20</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label22</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label32</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label5</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label21</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label16</Trans>{" "}
              <a
                href="https://www.centralbank.ie"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.centralbank.ie
              </a>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label15</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label17</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label24</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label31</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label18</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label33</Trans>
            </label>
            <br />
          </div>
        )}
        {language === "fi_fi" && (
          <div>
            <label>
              <Trans>docs.tnc.section.2.label2</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label3</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label4</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label5</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label6</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label7</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label8</Trans>
            </label>
            <br />
            <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label9</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label10</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label11</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label12</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label13</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label14</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label15</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label16</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label17</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label18</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label19</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label20</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label21</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label22</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label23</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label24</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label25</Trans>
              <a
                href="https://www.centralbank.ie"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.centralbank.ie
              </a>
              .
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label26</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label27</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label28</Trans>
            </label>
            <br />
            <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.2.label29</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label30</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.2.label31</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.2.label32</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.2.label33</Trans>
            </label>
            <br />
          </div>
        )}
      </div>

      <div className="eligibility-cover paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.3.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.3.label1</Trans>
        </label>
        <br />
        <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
          <li>
            <Trans>docs.tnc.section.3.label2</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.3.label3</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.3.label4</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.3.label5</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.3.label6</Trans>
          </li>
        </ol>
        {language !== "fi_fi" && (
          <label>
            <Trans>docs.tnc.section.3.label7</Trans>
          </label>
        )}
      </div>

      <div className="premiums paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.4.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.4.label1</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.4.label2</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.4.label3</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.4.label4</Trans>
        </label>
        <br />
        {language === "fi_fi" && (
          <label>
            <Trans>docs.tnc.section.4.label5</Trans>
          </label>
        )}
      </div>

      <div className="what-covered paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.5.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.5.label1</Trans>
        </label>
        <br />
        <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
          <li>
            <Trans>docs.tnc.section.5.label2</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.5.label3</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.5.label4</Trans>
          </li>
        </ol>
        <label>
          <Trans>docs.tnc.section.5.label5</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.5.label6</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.5.label7</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.5.label8</Trans>
        </label>
      </div>

      {language === "fi_fi" ? (
        <div className="duration-limit paddingBottom24 display-16">
          <p className="section-tnc">
            <Trans>docs.tnc.section.6.title</Trans>
          </p>
          <label>
            {isMonthly === true ? (<Trans>docs.tnc.section.6.label1</Trans>) : (<Trans>docs.tnc.section.6.label9</Trans>)}
          </label>
          <br />
          <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
            <li>
              <Trans>docs.tnc.section.6.label2</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.6.label3</Trans>
            </li>
          </ol>
          {isMonthly === true && (
              <label>
                <Trans components={{ u: <u /> }}>docs.tnc.section.6.label4</Trans>
              </label>
          )}
          <br />
          <label>
            <Trans components={{ u: <u /> }}>docs.tnc.section.6.label5</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.6.label6</Trans>
          </label>
          <br />
          <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }}>
            <li>
              <Trans>docs.tnc.section.6.label7</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.6.label8</Trans>
            </li>
          </ol>
        </div>
      ) : (
        <div className="duration-limit paddingBottom24 display-16">
          <p className="section-tnc">
            <Trans>docs.tnc.section.6.title</Trans>
          </p>
          <label>
            {isMonthly === true ? (<Trans>docs.tnc.section.6.label1</Trans>) : (<Trans>docs.tnc.section.6.label7</Trans>)}
          </label>
          <br />
          <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
            <li>
              <Trans>docs.tnc.section.6.label2</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.6.label3</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.6.label4</Trans>
            </li>
          </ol>
          {isMonthly === true && (
              <label>
                <Trans components={{ u: <u /> }}>docs.tnc.section.6.label5</Trans>
              </label>
          )}
          <br />
          <label>
            <Trans components={{ u: <u /> }}>docs.tnc.section.6.label6</Trans>
          </label>
        </div>
      )}

      <div className="ww-insurance-cover paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.7.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.7.label1</Trans>
        </label>
        <br />
        <label>
          <Trans>docs.tnc.section.7.label2</Trans>
        </label>
      </div>

      <div className="what-not-cover paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.8.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.8.label1</Trans>
        </label>
        <br />
        <ol className="lower-roman-parentheses" style={{ padding: "0 20px 20px 20px" }}>
          <li>
            <Trans>docs.tnc.section.8.label2</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label3</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label4</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label5</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label6</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label7</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label8</Trans>
            <ol className={`lower-alpha-parentheses ${language === "fi_fi"? "second":""}`}>
              <li>
                <Trans>docs.tnc.section.8.label9</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.8.label10</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.8.label11</Trans>
              </li>
            </ol>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label12</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label13</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label14</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label15</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label16</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label17</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label18</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label19</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label20</Trans>
          </li>
          <li>
            <Trans>docs.tnc.section.8.label21</Trans>
          </li>
        </ol>
        <label>
          <Trans>docs.tnc.section.8.label22</Trans>
        </label>
      </div>

      <div className="withdraw-cover paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.9.title</Trans>
        </p>
        {language === "fi_fi" ? (
          <>
            <label>
              <Trans>docs.tnc.section.9.label1</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.9.label2</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.9.label3</Trans>
            </label>
            <br />
          </>
        ) : (
          <>
            <label>
              <Trans>docs.tnc.section.9.label1</Trans>
            </label>
            <br />
            <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
              <li>
                <Trans>docs.tnc.section.9.label2</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.9.label3</Trans>
              </li>
              <li>
                <Trans>docs.tnc.section.9.label4</Trans>
              </li>
            </ol>
            <label>
              <Trans>docs.tnc.section.9.label5</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.9.label6</Trans>
            </label>
          </>
        )}
      </div>

      {language !== "fi_fi" && (
        <div className="rights-cancel paddingBottom24 display-16">
          <p className="section-tnc">
            <Trans>docs.tnc.section.10.title</Trans>
          </p>
          <label>
            <Trans>docs.tnc.section.10.label1</Trans>
          </label>
          <br />
          <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
            <li>
              <Trans>docs.tnc.section.10.label2</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.10.label3</Trans>
            </li>
          </ol>
          <label>
            <Trans>docs.tnc.section.10.label4</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.10.label5</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.10.label6</Trans>
          </label>
        </div>
      )}

      <div className="cancellation-insurer paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.11.title</Trans>
        </p>
        {language === "fi_fi" ? (
          <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
            <li>
              <Trans>docs.tnc.section.11.label1</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.11.label2</Trans>
              <ol className="lower-alpha-parentheses second">
                <li>
                  <Trans>docs.tnc.section.11.label3</Trans>
                </li>
                <li>
                  <Trans>docs.tnc.section.11.label4</Trans>
                </li>
                <li>
                  <Trans>docs.tnc.section.11.label5</Trans>
                </li>
                <li>
                  <Trans>docs.tnc.section.11.label6</Trans>
                </li>
                <li>
                  <Trans>docs.tnc.section.11.label7</Trans>
                </li>
              </ol>
            </li>
            <li>
              <Trans>docs.tnc.section.11.label8</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.11.label9</Trans>
            </li>
          </ol>
        ) : (
          <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
            <li>
              <Trans>docs.tnc.section.11.label1</Trans>
              <ol className="lower-alpha-parentheses" style={{ padding: "0 20px" }}>
                <li>
                  <Trans>docs.tnc.section.11.label2</Trans>
                </li>
                <li>
                  <Trans>docs.tnc.section.11.label3</Trans>
                </li>
                <li>
                  <Trans>docs.tnc.section.11.label4</Trans>
                </li>
              </ol>
            </li>
            <li>
              <Trans>docs.tnc.section.11.label5</Trans>
            </li>
            <li>
              <Trans>docs.tnc.section.11.label6</Trans>
            </li>
          </ol>
        )}
      </div>

      <div className="contact-aaa paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.12.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.12.label1</Trans>
        </label>
        <br />
        <ol className="lower-roman-parentheses" style={{ padding: "0 0 0 20px" }}>
          <li>
            <label className="underline_font">
              <Trans>docs.tnc.section.12.label2</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.12.label3</Trans>
            </label>
            <br />
            <div className="grid-wrap">
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label10</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="https://be.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://be.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="https://nl.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://nl.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="https://fi.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://fi.careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label11</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="mailto:support.be@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    support.be@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="mailto:support.nl@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    support.nl@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="mailto:support.fi@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    support.fi@careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label12</Trans></strong>
              </div>
              <div className="grid-table value">
                <Trans>docs.tnc.section.12.label15</Trans>
              </div>
            </div>
          </li>
          <li>
            <label className="underline_font">
              <Trans>docs.tnc.section.12.label4</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.12.label5</Trans>
            </label>
            <br />
            <div className="grid-wrap">
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label10</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="https://be.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://be.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="https://nl.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://nl.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="https://fi.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://fi.careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label11</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="mailto:complaints.be@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    complaints.be@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="mailto:complaints.nl@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    complaints.nl@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="mailto:complaints.fi@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    complaints.fi@careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label12</Trans></strong>
              </div>
              <div className="grid-table value">
                <Trans>docs.tnc.section.12.label14</Trans>
              </div>
            </div>
          </li>
          <li>
            <label className="underline_font">
              <Trans>docs.tnc.section.12.label6</Trans>
            </label>
            <br />
            <label>
              <Trans components={{ u: <u /> }}>docs.tnc.section.12.label7</Trans>
            </label>
            <br />
            <div className="grid-wrap">
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label10</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="https://be.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://be.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="https://nl.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://nl.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="https://fi.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://fi.careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label11</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="mailto:contact.be@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    contact.be@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="mailto:contact.nl@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    contact.nl@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="mailto:contact.fi@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    contact.fi@careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label12</Trans></strong>
              </div>
              <div className="grid-table value">
                <Trans>docs.tnc.section.12.label14</Trans>
              </div>
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label13</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">+32 7 807 9260</div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">+31858881836</div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">+358 753 254 781</div>
              )}
            </div>
          </li>
          <li>
            <label className="underline_font">
              <Trans>docs.tnc.section.12.label8</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.12.label9</Trans>
            </label>
            <br />
            <div className="grid-wrap">
              <div className="grid-table">
              {language==="be_fr"? <strong><Trans>docs.tnc.section.12.label10a</Trans></strong>:<strong><Trans>docs.tnc.section.12.label10</Trans></strong>}
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="https://be.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://be.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="https://nl.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://nl.careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="https://fi.careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    https://fi.careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label11</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">
                  <a
                    href="mailto:contact.be@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    contact.be@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">
                  <a
                    href="mailto:contact.nl@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    contact.nl@careplus.co
                  </a>
                </div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">
                  <a
                    href="mailto:contact.fi@careplus.co"
                    tabIndex="-1"
                    style={{ color: "#0077CB" }}
                  >
                    contact.fi@careplus.co
                  </a>
                </div>
              )}
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label12</Trans></strong>
              </div>
              <div className="grid-table value">
                <Trans>docs.tnc.section.12.label14</Trans>
              </div>
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.12.label13</Trans></strong>
              </div>
              {env.GATSBY_COUNTRY === "BE" && (
                <div className="grid-table value">+32 7 807 9260</div>
              )}
              {env.GATSBY_COUNTRY === "NL" && (
                <div className="grid-table value">+31858881836</div>
              )}
              {env.GATSBY_COUNTRY === "FI" && (
                <div className="grid-table value">+358 753 254 781</div>
              )}
            </div>
          </li>
        </ol>
      </div>

      <div className="claim-process paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.13.title</Trans>
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label1</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label2</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label3</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label4</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label5</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label6</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label7</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label8</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label9</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label10</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label11</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label12</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label13</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label14</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label15</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label16</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label17</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label18</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label19</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label20</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label21</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label22</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label23</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label24</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label25</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label26</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label27</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label28</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label29</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label30</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label31</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label32</Trans>
          </label>
          <br />
        </p>
        <p>
          <label className="underline_font">
            <strong><Trans>docs.tnc.section.13.label33</Trans></strong>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label34</Trans>
          </label>
          <br />
          <label>
            <Trans>docs.tnc.section.13.label35</Trans>
          </label>
          <br />
        </p>
      </div>

      <div className="sc-complaints paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.14.title</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.14.label1</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.14.label2</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.14.label3</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.14.label4</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.14.label5</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.14.label6</Trans>
        </p>
        {language === "fi_fi" && (
          <>
            <div className="grid-wrap" style={{ padding: "5px" }}>
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.14.label8</Trans></strong>
              </div>
              <div className="grid-table value">+358 29 505 3050</div>
              <div className="grid-table">
                <strong><Trans>docs.tnc.section.14.label10</Trans></strong>
              </div>
              <div className="grid-table" style={{ color: "#0077CB",wordBreak: "break-word" }} >
                <a
                  href="https://www.kkv.fi/kuluttajaneuvonta"
                  tabIndex="-1"
                  style={{ color: "#0077CB" }}
                >
                  https://www.kkv.fi/kuluttajaneuvonta
                </a>
              </div>
            </div>
            <label>Kuluttajariitalautakunta, yhteystiedot:</label>
          </>
        )}
        <div className="grid-wrap" style={{ padding: "5px" }}>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label7</Trans></strong>
          </div>
          {env.GATSBY_COUNTRY === "BE" && (
            <div className="grid-table value">
              {language === 'be_fr' ?
                <>
                Ombudsman des assurances <br />
                Square de Meeûs 35 <br />
                1000 Bruxelles</>:
                <>
                De Ombudsman van de Verzekeringen <br />
                De Meeûssquare 35 <br />
                1000 Brussel</>
              }
            </div>
          )}
          {env.GATSBY_COUNTRY === "NL" && (
            <div className="grid-table value">
              Klachteninstituut Financiële Dienstverlening (Kifid), <br />
              Postbus 93257, <br />
              2509 AG Den Haag
            </div>
          )}
          {env.GATSBY_COUNTRY === "FI" && (
            <div className="grid-table value">
              Kuluttajariitalautakunta <br />
              Hämeentie 3 <br />
              P.O. Box 3066 <br />
              00531 Helsinki
            </div>
          )}
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label8</Trans></strong>
          </div>
          {env.GATSBY_COUNTRY === "BE" && (
            <div className="grid-table value">+32 2 547 58 71</div>
          )}
          {env.GATSBY_COUNTRY === "NL" && (
            <div className="grid-table value">070-333 8 999</div>
          )}
          {env.GATSBY_COUNTRY === "FI" && (
            <div className="grid-table value">+358 29 566 5200</div>
          )}
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label9</Trans></strong>
          </div>
          {env.GATSBY_COUNTRY === "BE" && (
            <div className="grid-table value">
              <a
                href="mailto:info@ombudsman.as"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                info@ombudsman.as
              </a>
            </div>
          )}
          {env.GATSBY_COUNTRY === "NL" && (
            <div className="grid-table value">
              <a
                href="mailto:consumenten@kifid.nl"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                consumenten@kifid.nl
              </a>
            </div>
          )}
          {env.GATSBY_COUNTRY === "FI" && (
            <div className="grid-table value">
              <a
                href="mailto:krill@oikeus.fi"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                krill@oikeus.fi
              </a>
            </div>
          )}
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label10</Trans></strong>
          </div>
          {env.GATSBY_COUNTRY === "BE" && (
            <div className="grid-table value" style={{ color: "#0077CB" }}>
              <a
                href="https://www.ombudsman.as"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.ombudsman.as
              </a>
            </div>
          )}
          {env.GATSBY_COUNTRY === "NL" && (
            <div className="grid-table value" style={{ color: "#0077CB" }}>
              <a
                href="https://www.kifid.nl/"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.kifid.nl/
              </a>
            </div>
          )}
          {env.GATSBY_COUNTRY === "FI" && (
            <div className="grid-table value" style={{ color: "#0077CB" }}>
              <a
                href="https://www.kuluttajariita.fi"
                tabIndex="-1"
                style={{ color: "#0077CB" }}
              >
                https://www.kuluttajariita.fi
              </a>
            </div>
          )}
        </div>
        <p>
          <Trans>docs.tnc.section.14.label12</Trans>
        </p>
        <div className="grid-wrap" style={{ padding: "5px" }}>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label7</Trans></strong>
          </div>
          <div className="grid-table value">
            <Trans>docs.tnc.section.14.label13</Trans>
          </div>
          <div className="grid-table">
            <strong>{language==='be_fr'?<Trans>docs.tnc.section.14.label8-1</Trans>:<Trans>docs.tnc.section.14.label8</Trans>}</strong>
          </div>
          <div className="grid-table value">+43 1-71100/805782</div>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label9</Trans></strong>
          </div>
          <div className="grid-table value">
            <a
              href="mailto:stefan.trojer@oesterreich.gv.at"
              tabIndex="-1"
              style={{ color: "#0077CB" }}
            >
              stefan.trojer@oesterreich.gv.at
            </a>
          </div>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label10</Trans></strong>
          </div>
          <div className="grid-table value" style={{ color: "#0077CB" }}>
            <a
              href="https://oesterreich.gv.at"
              tabIndex="-1"
              style={{ color: "#0077CB" }}
            >
              oesterreich.gv.at
            </a>
          </div>
        </div>
        <p>
          <Trans>docs.tnc.section.14.label14</Trans>
        </p>
        <div className="grid-wrap" style={{ padding: "5px" }}>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label7</Trans></strong>
          </div>
          <div className="grid-table value">
            <Trans>docs.tnc.section.14.label15</Trans>
          </div>
          <div className="grid-table">
            <strong>{language==='be_fr'?<Trans>docs.tnc.section.14.label8-1</Trans>:<Trans>docs.tnc.section.14.label8</Trans>}</strong>
          </div>
          <div className="grid-table value">+353 1 567 7000</div>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label9</Trans></strong>
          </div>
          <div className="grid-table value">
            <a
              href="mailto:info@fspo.ie"
              tabIndex="-1"
              style={{ color: "#0077CB" }}
            >
              info@fspo.ie
            </a>
          </div>
          <div className="grid-table">
            <strong><Trans>docs.tnc.section.14.label10</Trans></strong>
          </div>
          <div className="grid-table value" style={{ color: "#0077CB" }}>
            <a href="https://www.fspo.ie" tabIndex="-1" style={{ color: "#0077CB" }}>
              www.fspo.ie
            </a>
          </div>
        </div>
        {language !== "fi_fi" && (
          <p>
            <Trans>docs.tnc.section.14.label16</Trans>
          </p>
        )}
        <p>
          <Trans>docs.tnc.section.14.label17</Trans>{" "}
          <a href="http://ec.europa.eu/consumers/odr/" tabIndex="-1">
            http://ec.europa.eu/consumers/odr/
          </a>
          .
        </p>
        <p>
          <Trans>docs.tnc.section.14.label18</Trans>
        </p>
      </div>

      <div className="transfer-insurance paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.15.title</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.15.label1</Trans>
        </p>
      </div>

      <div className="cancellation-insurer paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.16.title</Trans>
        </p>
        <ol className="lower-roman-parentheses" style={{ padding: "0 20px" }}>
          <li>
            <label className={env.GATSBY_COUNTRY !== "NL"?"underline_font":""}>
              <Trans>docs.tnc.section.16.label1</Trans>
            </label>
            <br />
            <Trans>docs.tnc.section.16.label2</Trans>
          </li>
          <li>
            <label className={env.GATSBY_COUNTRY !== "NL"?"underline_font":""}>
              <Trans>docs.tnc.section.16.label3</Trans>
            </label>
            <br />
            <Trans>docs.tnc.section.16.label4</Trans>
            {language === "fi_fi" && (
              <>
                <ol className="lower-alpha-parentheses second">
                  <li>
                    olet laiminlyönyt kohdassa (i) yllä mainitun
                    tiedonantovelvollisuutesi; tai
                  </li>
                  <li>
                    joko vakuutussopimusta tehtäessä Vakuutuksenantajalle
                    taiVakuutuksenottajalle ilmoittamissasi olosuhteissa
                    taivakuutuskirjaan merkityssä asiantilassa on
                    Vakuutuskaudenaikana tapahtunut muutos, tai Käyttöehdoissa
                    olevissaolosuhteissa, mikä nostaa materiaalisesti riskejä.
                  </li>
                </ol>
                <p>
                  Tällöin Sinulle ilmoitetaan, miten ja milloin vakuutusehtoja
                  on tarkoitus muuttaa. Saat tiedon oikeudestasi irtisanoa
                  vakuutusen.
                </p>
              </>
            )}
          </li>
          <li>
            <label className={env.GATSBY_COUNTRY !== "NL"?"underline_font":""}>
              <Trans>docs.tnc.section.16.label5</Trans>
            </label>
            <br />
            <label>
              <Trans>docs.tnc.section.16.label6</Trans>
            </label>
            {language === "fi_fi" && (
              <>
                <ol className="lower-alpha-parentheses second">
                  <li>
                    uusi tai muuttunut lainsäädäntö tai viranomaismääräys;
                  </li>
                  <li>oikeuskäytännön muuttuminen;</li>
                  <li>
                    ennalta arvaamaton olosuhteiden muutos (esim. kansainvälinen
                    kriisi, poikkeuksellinen luonnonilmiö, katastrofi,
                    suuronnettomuus);
                  </li>
                  <li>
                    vakuutukseen vaikuttavan indeksin tai muun viitearvon
                    muutos; tai
                  </li>
                  <li>korvausmenon tai kustannustason muutos.</li>
                  <p>
                    Vakuutuksenantajalla on myös oikeus tehdä vähäisiä muutoksia
                    tämän vakuutussopimuksen ehtoihin edellyttäen, että
                    muutokset eivät vaikuta vakuutuksen keskeiseen sisältöön.
                  </p>
                </ol>
                <p>
                  Tällöin Sinulle ilmoitetaan, miten ja milloin vakuutusehtoja
                  on tarkoitus muuttaa. Saat tiedon oikeudestasi irtisanoa
                  vakuutuksen. Muutos tulee voimaan sen Vakuutuskauden alusta
                  lukien, joka ensiksi seuraa kuukauden kuluttua siitä, kun
                  Vakuutuksenantaja on lähettänyt Sinulle ilmoituksen
                  sopimusehtojen muuttumisesta.
                </p>
              </>
            )}
          </li>
          {language === "fi_fi" ? (
            <>
              <li>
                <label className="underline_font">
                  <Trans>docs.tnc.section.16.label7</Trans>
                </label>
                <br />
                <Trans>docs.tnc.section.16.label8</Trans>
              </li>
            </>
          ) : (
            <>
              <li>
                <label className={language==='be_fr'?"underline_font":""}>
                    <Trans>docs.tnc.section.16.label7</Trans>
                </label>
                {language === 'be_fr' && (
                    <>
                       <br/>
                       <Trans>docs.tnc.section.16.label8</Trans>
                    </>
                )}
              </li>
              <li>
                {language !== 'be_fr'?
                    <Trans>docs.tnc.section.16.label8</Trans>:
                    <>
                     <label className="underline_font">
                        <Trans>docs.tnc.section.16.label9</Trans>
                     </label>
                     <br/>
                     <Trans>docs.tnc.section.16.label10</Trans>
                    </>}
              </li>
            </>
          )}
        </ol>
      </div>

      {language !== "fi_fi" && (
        <div className="double-insurance paddingBottom24 display-16">
          <p className="section-tnc">
            <Trans>docs.tnc.section.17.title</Trans>
          </p>
          <p>
            <Trans>docs.tnc.section.17.label1</Trans>
          </p>
          <p>
            <Trans>docs.tnc.section.17.label2</Trans>
          </p>
        </div>
      )}
      <div className="double-insurance paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.18.title</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.18.label1</Trans>
        </p>
      </div>

      <div className="double-insurance paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.19.title</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.19.label1</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.19.label2</Trans>
        </p>
        <p>
          <Trans>docs.tnc.section.19.label3</Trans>
        </p>
      </div>

      <div className="double-insurance paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.20.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.20.label1</Trans>
        </label>
      </div>

      <div className="double-insurance paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.21.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.21.label1</Trans>
        </label>
      </div>

      <div className="double-insurance paddingBottom24 display-16">
        <p className="section-tnc">
          <Trans>docs.tnc.section.22.title</Trans>
        </p>
        <label>
          <Trans>docs.tnc.section.22.label1</Trans>
        </label>
      </div>
    </div>
  )
}
