import {decorate, observable, action, extendObservable} from "mobx";
import { create, persist } from 'mobx-persist'

class DeviceStore {
    deviceImei = '';
    deviceModel = ''
    deviceModelDesc = ''
    deviceRrp = ''
    deviceError = null
    deviceActivationDate = ''
    deviceType = null;
    deviceSerialNumber = null;
    isRenewal = false;
    productsModel = '';
    voucherProductCodes = '';
    voucherCode = '';
    cid = '';

    updateDeviceDetail = (deviceDetail) => {   //will not be used in release 1.5
        this.deviceImei = deviceDetail.deviceImei;
        this.deviceModel = deviceDetail.deviceModel;
        this.deviceRrp = deviceDetail.deviceRrp
    }

    updateDeviceInfo = (deviceModel, deviceRrp) => {
        this.deviceModel = deviceModel;
        this.deviceRrp = deviceRrp;
    }

    updateDeviceActivationDate = (activationDate) => {
        this.deviceActivationDate = activationDate;
    }

    updateDeviceIMEI = (deviceIMEI) => {
        this.deviceImei = deviceIMEI;
    }

    updateError = (errorMessage) => {
        this.deviceError = errorMessage
    }

    updateDeviceType = (type) => {
        this.deviceType = type
    }
    
    reset() {
        this.deviceImei = ''
        this.deviceModel = ''
        this.deviceModelDesc = ''
        this.deviceRrp = ''
        this.deviceError = null
        this.deviceActivationDate = ''
        this.deviceType = null
        this.deviceSerialNumber = null
        this.isRenewal = false
        this.productsModel = ''
        this.voucherProductCodes = ''
        this.voucherCode = ''
        this.cid = ''
    }
}

decorate(DeviceStore, {
    deviceImei: [persist, observable],
    deviceModelDesc: [persist, observable],
    deviceModel: [persist, observable],
    deviceRrp: [persist, observable],
    deviceError: [persist, observable],
    deviceActivationDate: [persist, observable],
    deviceType: [persist, observable],
    deviceSerialNumber: [persist, observable],
    isRenewal: [persist, observable],
    productsModel: [persist, observable],
    voucherProductCodes: [persist, observable],
    voucherCode: [persist, observable],
    cid: [persist, observable],
    updateDeviceActivationDate: action,
    updateDeviceDetail: action,
    updateDeviceInfo: action,
    updateDeviceIMEI: action,
    updateError: action,
    reset: action,
})
var deviceStore = new DeviceStore();
export default deviceStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('deviceStore', deviceStore)
}

